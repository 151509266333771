<template>
  <div v-if="is_model" class="model-header">
    <div
      class="model-header-title"
      v-if="
        setbrandid == 'RDU' ||
        setbrandid == 'YHM' ||
        setbrandid == 'SV1' ||
        setbrandid == 'OKC' ||
        setbrandid == 'DEN' ||
        setbrandid == 'SAT' ||
        setbrandid == 'PV1' ||
        setbrandid == 'PIT' ||
        setbrandid == 'SV1' 
      "
    >
      {{ getrans("Your Cart") }}
    </div>
    <div v-else>
      {{ getrans("Your Basket") }}
    </div>
    <div class="model-header-close" @click="toggle_basket_model">
      <i class="fa fa-times"></i>
    </div>
  </div>

  <div
    v-if="setbrandid"
    class="page-content__basket-overview page-content__basket--box-style"
    :class="is_model ? 'is-model' : ''"
    style="padding-bottom: 0.5rem; margin-bottom: 1rem"
  >
    <!-- PARKING -->
    <div class="basket-item" v-if="item_title">
      <span
        v-if="
          prop_parking_cartitem &&
          prop_parking_cartitem.product_display_name &&
          setbrandid != 'RDU' &&
          setbrandid != 'NWI' &&
          setbrandid != 'HER'
        "
        class="basket-item__title"
        >{{ getrans(prop_parking_cartitem.product_display_name) }}</span
      >
      <span v-else class="basket-item__title">{{ getrans(item_title) }}</span>

      <div class="basket-item__dates">
        <div class="basket-item__block">
          <span class="basket-item__start_date"
            ><i class="far fa-calendar"></i>&nbsp;{{
              item_start_date_m
            }}&nbsp;</span
          >
          <span class="basket-item__start_time"
            ><i class="far fa-clock"></i>&nbsp;{{
              item_start_time_m
            }}&nbsp;</span
          >
        </div>

        <span class="basket-item__start_to"
          >&nbsp;{{ getrans("to") }}&nbsp;</span
        >

        <div class="basket-item__block">
          <span class="basket-item__end_date"
            ><i class="far fa-calendar"></i>&nbsp;{{
              item_end_date_m
            }}&nbsp;</span
          >
          <span class="basket-item__end_time"
            ><i class="far fa-clock"></i>&nbsp;{{ item_end_time_m }}&nbsp;</span
          >
        </div>
      </div>

      <div
        class="basket-item__charges"
        v-if="
          prop_parking_cartitem &&
          prop_parking_cartitem.fees_applicable &&
          prop_parking_cartitem.fees_applicable == 'Y'
        "
      >
        {{ pricefeesdisplay || prop_parking_cartitem.pricefeesdisplay }}
      </div>
      <div class="basket-item__footer">
        <span class="basket-item__price" v-if="totalvisible"
          >{{ currency }} {{ item_price }}</span
        >
        <span class="basket-item__price" v-if="totalcomputedvisible"
          >{{ currency }} {{ finaltotalvalue_cpk.toFixed(2) }}</span
        >
        <div class="basket-item__buttons">
          <button @click="checkcrosselledit('CPK')">
            {{ getrans("Edit") }} <i class="fas fa-edit"></i>
          </button>
          <button @click="remove_cart">
            {{ getrans("Delete") }} <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- {{ prop_lounge_cartitem }} -->
    <!-- LOUNGE -->
    <div class="basket-item" v-if="prop_lounge_cartitem">
      <span class="basket-item__title">{{
        getrans(prop_lounge_cartitem.product_name)
      }}</span>

      <div class="basket-item__dates">
        <div class="basket-item__block basket-item__block--no-end-date">
          <span class="basket-item__start_date"
            ><i class="far fa-calendar"></i>
            {{ prop_lounge_cartitem.date_selected_display }}</span
          >
          <!-- <span v-if="setbrandid == 'HER'" class="basket-item__start_time"
            ><i class="far fa-clock"></i>
            {{ formatTime(prop_lounge_cartitem.time_selected) }}</span
          > -->
          <span class="basket-item__start_time"
            ><i class="far fa-clock"></i>
            {{ prop_lounge_cartitem.time_selected }}</span
          >
          <span class="basket-item__total-people"
            ><i class="fas fa-user"></i>
            {{
              Number(prop_lounge_cartitem.ADL) +
              Number(prop_lounge_cartitem.CHD) +
              Number(prop_lounge_cartitem.INF)
            }}</span
          >
        </div>
      </div>

      <div class="basket-item__age-groups">
        <span
          class="basket-item__age-group"
          v-if="Number(prop_lounge_cartitem.ADL) > 0"
          >{{ getrans("Adults") }}: {{ prop_lounge_cartitem.ADL }}</span
        >
        <span
          class="basket-item__age-group"
          v-if="Number(prop_lounge_cartitem.CHD) > 0"
          >{{ getrans("Children") }}: {{ prop_lounge_cartitem.CHD }}</span
        >
        <span
          class="basket-item__age-group"
          v-if="Number(prop_lounge_cartitem.INF) > 0"
          >{{ getrans("Infants") }}: {{ prop_lounge_cartitem.INF }}</span
        >
      </div>

      <div class="basket-item__footer">
        <span class="basket-item__price" v-if="totalvisible"
          >{{ currency }} {{ prop_lounge_cartitem.loungesubtotal }}</span
        >
        <span class="basket-item__price" v-if="totalcomputedvisible"
          >{{ currency }} {{ finaltotalvalue_lng.toFixed(2) }}</span
        >
        <div class="basket-item__buttons">
          <button @click="checkcrosselledit('LNG')">
            {{ getrans("Edit") }} <i class="fas fa-edit"></i>
          </button>
          <button @click="delete_lounge(false)">
            {{ getrans("Delete") }} <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- FASTTRACK -->
    <div class="basket-item" v-if="prop_fasttrack_cartitem">
      <template
        v-for="(prop_fasttrack_cartitem_item, i) in prop_fasttrack_cartitem"
        :key="'FTT-' + i"
      >
        <span class="basket-item__title">{{
          getrans(prop_fasttrack_cartitem_item.product_name)
        }}</span>

        <div class="basket-item__dates">
          <div class="basket-item__block basket-item__block--no-end-date">
            <span class="basket-item__start_date"
              ><i class="far fa-calendar"></i>
              {{ prop_fasttrack_cartitem_item.date_selected_display }}</span
            >
            <span class="basket-item__start_time"
              ><i class="far fa-clock"></i>
              {{ prop_fasttrack_cartitem_item.time_selected }}</span
            >

            <span class="basket-item__total-people"
              ><i class="fas fa-user"></i>
              {{
                Number(prop_fasttrack_cartitem_item.ADL) +
                Number(prop_fasttrack_cartitem_item.CHD) +
                Number(prop_fasttrack_cartitem_item.INF)
              }}</span
            >
          </div>
        </div>

        <div class="basket-item__age-groups">
          <span
            class="basket-item__age-group"
            v-if="Number(prop_fasttrack_cartitem_item.ADL) > 0"
            >{{ getrans("Adults") }}:
            {{ prop_fasttrack_cartitem_item.ADL }}</span
          >
          <span
            class="basket-item__age-group"
            v-if="Number(prop_fasttrack_cartitem_item.CHD) > 0"
            >{{ getrans("Children") }}:
            {{ prop_fasttrack_cartitem_item.CHD }}</span
          >
          <span
            class="basket-item__age-group"
            v-if="Number(prop_fasttrack_cartitem_item.INF) > 0"
            >{{ getrans("Infants") }}:
            {{ prop_fasttrack_cartitem_item.INF }}</span
          >
        </div>

        <div class="basket-item__footer">
          <span class="basket-item__price" v-if="totalvisible"
            >{{ currency }}
            {{
              Number(prop_fasttrack_cartitem_item.fasttracksubtotal).toFixed(2)
            }}</span
          >
<span class="basket-item__price" v-if="totalcomputedvisible"
            >{{ currency }} {{ finaltotalvalue_ftt["ftt"+prop_fasttrack_cartitem_item.product_id] ? Number(finaltotalvalue_ftt["ftt"+prop_fasttrack_cartitem_item.product_id]).toFixed(2) : Number(prop_fasttrack_cartitem_item.fasttracksubtotal).toFixed(2)}}</span
          >
          <div class="basket-item__buttons">
            <button @click="checkcrosselledit('FTT')">
              {{ getrans("Edit") }} <i class="fas fa-edit"></i>
            </button>
            <button @click="delete_fasttrack(i)">
              {{ getrans("Delete") }} <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>

  <div class="model-footer" v-if="is_model">
    <div class="model-footer-subtotal">
      <span
        v-if="
          setbrandid == 'RDU' ||
          setbrandid == 'YHM' ||
          setbrandid == 'SV1' ||
          setbrandid == 'OKC' ||
          setbrandid == 'DEN' ||
          setbrandid == 'SAT' ||
          setbrandid == 'PV1' ||
          setbrandid == 'PIT' ||
          setbrandid == 'SV1'
        "
        >{{ getrans("Cart Subtotal:") }}&nbsp;</span
      >
      <span v-else>{{ getrans("Basket Subtotal") }}:&nbsp;</span>
      <span
        >{{ currency }}
        {{
          parseFloat(finaltotalvalue).toFixed(2) > 0
            ? parseFloat(finaltotalvalue).toFixed(2)
            : parseFloat(total_to_emit).toFixed(2)
        }}</span
      >
    </div>
    <button class="model-footer-proceed" @click="gotoPage('/yourdetails.html')">
      <template v-if="setbrandid == 'SV1'">
        {{ getrans("Checkout") }}
      </template>
      <template v-else>
        {{ getrans("Proceed") }}
      </template>
    </button>
  </div>

  <div
    v-if="!is_model"
    class="page-content__basket--box-style promo-box"
    style="padding-bottom: 0.5rem"
  >
    <span class="basket-item__title">{{
      getrans("Do you have a promo code?")
    }}</span>

    <div class="promo-wrap" v-if="totalcomputedvisible">
      <div>
        <span class="promo-wrap--save"
          >{{ getrans("You save") }}
          <b>{{ currency }} {{ discount_amount_applied }}</b></span
        >
        <span class="promo-wrap--applied"
          >{{ getrans("Promo code applied") }} :
          {{ discount_coupon_applied.toUpperCase() }}</span
        >
      </div>
      <span @click="remove_promo"><i class="fas fa-trash-alt"></i></span>
    </div>

    <div class="promo-input-btn-wrap" v-if="!totalcomputedvisible">
      <input
        class="promo-input"
        name="discountcode"
        autofocus="autofocus"
        type="text"
        v-model="discountcode"
        data-lpignore="true"
        @keyup.enter="proceedtocheckdiscountcode"
      />
      <button
        class="promo-update-button"
        label="Yes"
        @click="proceedtocheckdiscountcode"
        autofocus
      >
        <i v-if="loadingPromoCode" class="fas fa-circle-notch fa-spin"></i
        ><span v-if="!loadingPromoCode">{{ getrans("Apply") }}</span>
      </button>
    </div>
  </div>

  <!-- <Dialog
    class="promo-dialog"
    header=""
    v-model:visible="displayModal"
    :style="{ width: 'auto' }"
    :modal="true"
  >
    <template #header>
      <h5>Enter Promocode</h5>
    </template>

    <input
      class="promo-input"
      name="discountcode"
      autofocus="autofocus"
      type="text"
      v-model="discountcode"
      data-lpignore="true"
      @keyup.enter="proceedtocheckdiscountcode"
    />

    <template #footer>
      <button label="No" @click="closeModal" class="p-button-text">
        Close
      </button>
      <button
        class="promo-update-button"
        label="Yes"
        @click="proceedtocheckdiscountcode"
        autofocus
      >
        <i v-if="loadingPromoCode" class="fas fa-circle-notch fa-spin"></i
        >&nbsp;<span v-if="!loadingPromoCode">Apply</span
        ><span v-if="loadingPromoCode">Checking...</span>
      </button>
    </template>
  </Dialog> -->

  <!-- keep this it updates the basket total in parent -->
  <div style="display: none">{{ total_to_emit }}</div>
</template>
<script>
import md5 from "js-md5";
const axios = require("axios");
const genkeys = require("rezauthlib");
const crypto = require("crypto");
import Swal from "sweetalert2";
const { EncryptStorage } = require("encrypt-storage");
const encryptStorage = new EncryptStorage(process.env.VUE_APP_CHL, {
  storageType: "sessionStorage",
});
export default {
  name: "BasketItem",
  props: {
    prop_parking_cartitem: undefined,
    prop_fasttrack_cartitem: undefined,
    prop_lounge_cartitem: undefined,
    item_price: undefined,
    item_title: undefined,
    item_start_date: undefined,
    item_start_time: undefined,
    item_end_date: undefined,
    item_end_time: undefined,
    lang: undefined,
    translations: undefined,
    brandSlug: undefined,
    remove_fasttrack_cartitem: () => {},
    remove_lounge_cartitem: () => {},
    update_cart_items: () => {},
    is_model: undefined,
    is_model_subtotal: undefined,
  },
  data() {
    return {
      goto_page_url: "/results.html",
      goto_page_url_ftt: "/fasttrack.html",
      goto_page_url_lng: "/lounge.html",
      //displayModal: false,
      loadingPromoCode: false,
      proceedLoader: false,
      totalvisible: true,
      discountcode: "",
      finaltotalvalue: 0,
      finaltotalvalue_cpk: 0,
      finaltotalvalue_ftt: [],
      finaltotalvalue_lng: 0,
      discount_coupon_applied: "",
      discount_amount_applied: 0,
      totalcomputedvisible: "",
      setbrandid: "",
      currency: "",
      item_start_date_m: "",
      item_start_time_m: "",
      item_end_date_m: "",
      item_end_time_m: "",
      pricefeesdisplay: "",
    };
  },
  emits: ["finaltotalvalueChange", "basketItemsNumber"],

  computed: {
 total_to_emit: function () {
      let total_to_emit = 0;
      let basket_items_number = 0;
      if (this.item_title && this.item_price) {
        total_to_emit = total_to_emit + Number(this.item_price);
        basket_items_number++;
      }
      console.log("total_to_emit cpk", total_to_emit);
      if (
        this.prop_fasttrack_cartitem &&
        this.prop_fasttrack_cartitem.length > 0
      ) {
        this.prop_fasttrack_cartitem.forEach(function (
          fasttrack_cartitem_arrayItem
        ) {
          if (fasttrack_cartitem_arrayItem.fasttracksubtotal) {
            total_to_emit =
              total_to_emit +
              Number(fasttrack_cartitem_arrayItem.fasttracksubtotal);
            basket_items_number++;
          }
        });
      }
      console.log("total_to_emit cpkftt", total_to_emit);
      if (
        this.prop_lounge_cartitem &&
        this.prop_lounge_cartitem.loungesubtotal
      ) {
        total_to_emit =
          total_to_emit + Number(this.prop_lounge_cartitem.loungesubtotal);
        basket_items_number++;
      }
      console.log("total_to_emit cpkfttlng", total_to_emit);
      let discount_amount = 0;
      if(typeof encryptStorage.getItem("discount_amount") !== "undefined")
      {
      discount_amount = encryptStorage.getItem("discount_amount");
      if (discount_amount === null) {
        discount_amount = 0;
      }
      }
      console.log("discount_amount cpkfttlng", discount_amount);
      discount_amount = parseFloat(discount_amount);      
      total_to_emit = parseFloat(total_to_emit);
      total_to_emit = total_to_emit - discount_amount;      

      console.log("total_to_emit", total_to_emit);
      this.$emit("finaltotalvalueChange", total_to_emit);
      this.$emit("basketItemsNumber", basket_items_number);

      return total_to_emit;
    },
  },
  methods: {
    delete_fasttrack(i) {
      if (encryptStorage.getItem("crosssell_shown") === "Yes") {
        let othp = 0;
        if (typeof encryptStorage.getItem("cart") !== "undefined") {
          let cn_ftt=0;
          let ftt_cart =  encryptStorage.getItem("fasttrack_cart");
          ftt_cart.forEach(function (
          fasttrack_cartitem_arrayItem
          ) {
            if(cn_ftt==i)
            {
              console.log('removing fasttrack_cartitem_arrayItem',fasttrack_cartitem_arrayItem);
              ftt_cart.splice(i, 1);
            }
            cn_ftt++;
          });  
          if(cn_ftt==1)
          {        
          encryptStorage.removeItem("fasttrack_cart");
          }
          else
          {
            encryptStorage.setItem("fasttrack_cart",ftt_cart);
          }
          encryptStorage.removeItem("discount_amount");
          encryptStorage.removeItem("discount_amount_applied");
          encryptStorage.removeItem("discount_coupon_applied");
          encryptStorage.removeItem("dis_ftt");
          encryptStorage.removeItem("dis_cpk");
          encryptStorage.removeItem("dis_lng");
          encryptStorage.removeItem("finaltotalvalue_cpk");
          encryptStorage.removeItem("finaltotalvalue_lng");
          encryptStorage.removeItem("finaltotalvalue_ftt");
          this.remove_fasttrack_cartitem();
          this.update_cart_items();
          this.checkcartempty();
        } else {
          if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
            if (encryptStorage.getItem("lounge_cart").crossell) {
              othp = othp + 1;
            }
          }
          if (othp == 0) {
            let cn_ftt=0;
          let ftt_cart =  encryptStorage.getItem("fasttrack_cart");
          ftt_cart.forEach(function (
          fasttrack_cartitem_arrayItem
          ) {
            if(cn_ftt==i)
            {
              console.log('removing fasttrack_cartitem_arrayItem',fasttrack_cartitem_arrayItem);
              ftt_cart.splice(i, 1);
            }
            cn_ftt++;
          });  
          if(cn_ftt==1)
          {        
          encryptStorage.removeItem("fasttrack_cart");
          }
          else
          {
            encryptStorage.setItem("fasttrack_cart",ftt_cart);
          }
            encryptStorage.removeItem("discount_amount");
            encryptStorage.removeItem("discount_amount_applied");
            encryptStorage.removeItem("discount_coupon_applied");
            encryptStorage.removeItem("dis_ftt");
            encryptStorage.removeItem("dis_cpk");
            encryptStorage.removeItem("dis_lng");
            encryptStorage.removeItem("finaltotalvalue_cpk");
            encryptStorage.removeItem("finaltotalvalue_lng");
            encryptStorage.removeItem("finaltotalvalue_ftt");
            this.remove_fasttrack_cartitem();
            this.update_cart_items();
            this.checkcartempty();
          } else {
            this.deletecrossellcart();
          }
        }
      } else {
        let cn_ftt=0;
          let ftt_cart =  encryptStorage.getItem("fasttrack_cart");
          ftt_cart.forEach(function (
          fasttrack_cartitem_arrayItem
          ) {
            if(cn_ftt==i)
            {
              console.log('removing fasttrack_cartitem_arrayItem',fasttrack_cartitem_arrayItem);
              ftt_cart.splice(i, 1);
            }
            cn_ftt++;
          });  
          if(cn_ftt==1)
          {        
          encryptStorage.removeItem("fasttrack_cart");
          }
          else
          {
            encryptStorage.setItem("fasttrack_cart",ftt_cart);
          }
        encryptStorage.removeItem("discount_amount");
        encryptStorage.removeItem("discount_amount_applied");
        encryptStorage.removeItem("discount_coupon_applied");
        encryptStorage.removeItem("dis_ftt");
        encryptStorage.removeItem("dis_cpk");
        encryptStorage.removeItem("dis_lng");
        encryptStorage.removeItem("finaltotalvalue_cpk");
        encryptStorage.removeItem("finaltotalvalue_lng");
        encryptStorage.removeItem("finaltotalvalue_ftt");
        this.remove_fasttrack_cartitem();
        this.update_cart_items();
        this.checkcartempty();
      }
    },
    lounge_unreserve(edit) {
      if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
        let data = {
          booking_ref_id: encryptStorage.getItem("lounge_cart").lng_order_ref,
          brand_id: this.setbrandid,
        };
        let secsaa = genkeys(
          process.env.VUE_APP_LOUNGE_UNRS_SECRETID,
          process.env.VUE_APP_LOUNGE_UNRS_SECRETKEY
        );
        let b1sa = secsaa.b1s;
        let v2sa = secsaa.v2s;
        let a1a = secsaa.a1;
        let v1a = secsaa.v1;
        let c2sa = secsaa.c2s;
        let uidgena = secsaa.uidgen;
        axios
          .post(process.env.VUE_APP_LOUNGEUNRESERVE_URL, data, {
            headers: {
              b1: b1sa,
              v2: v2sa,
              c2s: c2sa,
              a1: a1a,
              v1: v1a,
              c1: uidgena,
            },
          })
          .then((response) => {
            let datasent = JSON.parse(response.request.response);
            console.log("datasent", datasent);

            encryptStorage.removeItem("lounge_cart");
            encryptStorage.removeItem("discount_amount");
            encryptStorage.removeItem("discount_amount_applied");
            encryptStorage.removeItem("discount_coupon_applied");
            encryptStorage.removeItem("dis_ftt");
            encryptStorage.removeItem("dis_cpk");
            encryptStorage.removeItem("dis_lng");
            encryptStorage.removeItem("finaltotalvalue_cpk");
            encryptStorage.removeItem("finaltotalvalue_lng");
            encryptStorage.removeItem("finaltotalvalue_ftt");
            this.remove_lounge_cartitem();
            this.update_cart_items();
            //console.log(edit);
            if (edit) {
              this.goto_results_lng();
            }
          });
      }
    },
    delete_lounge(edit) {
      if (encryptStorage.getItem("crosssell_shown") === "Yes") {
        let othp = 0;
        if (typeof encryptStorage.getItem("cart") !== "undefined") {
          this.lounge_unreserve(edit);
        } else {
          if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
            if (encryptStorage.getItem("fasttrack_cart").crossell) {
              othp = othp + 1;
            }
          }
          if (othp == 0) {
            this.lounge_unreserve(edit);
            this.checkcartempty();
          } else {
            this.deletecrossellcart();
          }
        }
      } else {
        this.lounge_unreserve(edit);
        this.checkcartempty();
      }
    },
    checkcrosselledit(a) {
      console.log("a", a);
      if (a == "LNG") {
        if (encryptStorage.getItem("crosssell_shown") === "Yes") {
          if (encryptStorage.getItem("lounge_cart").crossell) {
            console.log("scroll lng");
            let path = window.location.pathname;
            if (path == "/crosssell.html") {
              this.scrollToElementlng();
            } else {
              window.location.href = "/crosssell.html";
            }
          } else {
            let othp = 0;
            if (
              typeof encryptStorage.getItem("fasttrack_cart") !== "undefined"
            ) {
              if (encryptStorage.getItem("fasttrack_cart").crossell) {
                othp = othp + 1;
              }
            }
            if (othp == 0) {
              this.goto_results_lng();
            } else {
              this.deletecrossellcart();
            }
          }
        } else {
          if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
            if (encryptStorage.getItem("lounge_cart").crossell) {
              console.log("scroll lng");
              let path = window.location.pathname;
              if (path == "/crosssell.html") {
                this.scrollToElementlng();
              } else {
                window.location.href = "/crosssell.html";
              }
            } else {
              this.goto_results_lng();
            }
          }
        }
      }
      if (a == "FTT") {
        if (encryptStorage.getItem("crosssell_shown") === "Yes") {
          if (encryptStorage.getItem("fasttrack_cart").crossell) {
            console.log("scroll ftt");
            let path = window.location.pathname;
            if (path == "/crosssell.html") {
              this.scrollToElementftt();
            } else {
              window.location.href = "/crosssell.html";
            }
          } else {
            let othp = 0;
            if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
              if (encryptStorage.getItem("lounge_cart").crossell) {
                othp = othp + 1;
              }
            }
            if (othp == 0) {
              this.goto_results_ftt();
            } else {
              this.deletecrossellcart();
            }
          }
        } else {
          if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
            if (encryptStorage.getItem("fasttrack_cart").crossell) {
              console.log("scroll ftt");
              let path = window.location.pathname;
              if (path == "/crosssell.html") {
                this.scrollToElementftt();
              } else {
                window.location.href = "/crosssell.html";
              }
            } else {
              this.goto_results_ftt();
            }
          }
        }
      }
      if (a == "CPK") {
        if (encryptStorage.getItem("crosssell_shown") === "Yes") {
          let othp = 0;
          if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
            if (encryptStorage.getItem("fasttrack_cart").crossell) {
              othp = othp + 1;
            }
          }
          if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
            if (encryptStorage.getItem("lounge_cart").crossell) {
              othp = othp + 1;
            }
          }
          if (othp == 0) {
            this.goto_results();
          } else {
            this.deletecrossellcart();
          }
        } else {
          this.goto_results();
        }
      }
      //this.delete_lounge(true);
    },
    checkcartempty() {
      let othp = 0;
      if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
        othp = othp + 1;
      }
      if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
        othp = othp + 1;
      }
      if (typeof encryptStorage.getItem("cart") !== "undefined") {
        othp = othp + 1;
      }
      if (othp == 0) {
        sessionStorage.removeItem("cart");
        sessionStorage.removeItem("fasttrack_cart");
        sessionStorage.removeItem("lounge_cart");
        sessionStorage.removeItem("orefwithoutprefix");
        encryptStorage.removeItem("cart");
        encryptStorage.removeItem("oldcart");
        encryptStorage.removeItem("tag_totcost");
        encryptStorage.removeItem("fasttrack_cart");
        encryptStorage.removeItem("final_orderreference");
        encryptStorage.removeItem("lounge_cart");
        encryptStorage.removeItem("orefwithoutprefix");
        encryptStorage.removeItem("order_ref");
        encryptStorage.removeItem("dis_cpk");
        encryptStorage.removeItem("dis_ftt");
        encryptStorage.removeItem("discount_amount");
        encryptStorage.removeItem("discount_amount_applied");
        encryptStorage.removeItem("discount_coupon_applied");
        encryptStorage.removeItem("final_amount_paid");
        encryptStorage.removeItem("orderplaced");
        encryptStorage.removeItem("crosssell_data");
        encryptStorage.removeItem("crosssell_shown");
        encryptStorage.removeItem("finaltotalvalue_cpk");
        encryptStorage.removeItem("finaltotalvalue_lng");
        encryptStorage.removeItem("finaltotalvalue_ftt");
        window.location.href = "/index.html";
      }
    },
    deletecrossellcart() {
      Swal.fire({
        title: this.getrans("Info"),
        text: this.getrans(
          "The items in your cart are part of a combined offer. Deleting the product will remove all items in your cart. Do you want to proceed?"
        ),
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: this.getrans("Yes"),
        denyButtonText: this.getrans("No"),
        customClass: {
          actions: "my-actions",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.removeItem("cart");
          sessionStorage.removeItem("fasttrack_cart");
          sessionStorage.removeItem("lounge_cart");
          sessionStorage.removeItem("orefwithoutprefix");
          encryptStorage.removeItem("cart");
          encryptStorage.removeItem("oldcart");
          encryptStorage.removeItem("tag_totcost");
          encryptStorage.removeItem("fasttrack_cart");
          encryptStorage.removeItem("final_orderreference");
          encryptStorage.removeItem("lounge_cart");
          encryptStorage.removeItem("orefwithoutprefix");
          encryptStorage.removeItem("order_ref");
          encryptStorage.removeItem("dis_cpk");
          encryptStorage.removeItem("dis_ftt");
          encryptStorage.removeItem("discount_amount");
          encryptStorage.removeItem("discount_amount_applied");
          encryptStorage.removeItem("discount_coupon_applied");
          encryptStorage.removeItem("final_amount_paid");
          encryptStorage.removeItem("orderplaced");
          encryptStorage.removeItem("crosssell_data");
          encryptStorage.removeItem("crosssell_shown");
          encryptStorage.removeItem("finaltotalvalue_cpk");
          encryptStorage.removeItem("finaltotalvalue_lng");
          encryptStorage.removeItem("finaltotalvalue_ftt");
          this.update_cart_items();
          window.location.href = "/index.html";
        }
      });
    },
    scrollToElementlng() {
      let el = document.getElementById("lngcross");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToElementftt() {
      let el = document.getElementById("fttcross");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    goto_results_lng() {
      const data = {
        date1_date: this.formatDate_M_D_Y(
          this.prop_lounge_cartitem.date_selected_display
        ),
        date1_time: this.prop_lounge_cartitem.time_selected,
        adults_select: this.prop_lounge_cartitem.ADL,
        children_select: this.prop_lounge_cartitem.CHD,
        infants_select: this.prop_lounge_cartitem.INF,
        venue_select: this.getLocalData("venue_selected_lng"),
        venue_select_name: this.getLocalData("venue_selected_name_lng"),
      };
      const querystring = "?" + this.encode_query_data(data);
      let uri = "";
      uri = this.goto_page_url_lng;
      this.goto_page(uri + querystring);
    },
    gotoPage(url, type = null) {
      if (url.indexOf("myaccountdev.") > -1 && this.lang) {
        url += "?lang=" + this.lang;
      }
      if (url.indexOf("myaccount.") > -1 && this.lang) {
        url += "?lang=" + this.lang;
      }
      if (type && type.toLowerCase() === "external") {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
    proceedtocheckdiscountcode() {
      //let vm = this;
      this.loadingPromoCode = true;
      let discountcode_entered = this.discountcode.trim();
      if (discountcode_entered == "") {
        //this.displayModal = false;
        Swal.fire({
          title: "Alert",
          icon: "error",
          text: "Please enter promo code to proceed.",
          confirmButtonText: "Close",
          showCloseButton: true,
        }).then(function () {
          //vm.displayModal = true;
        });
        this.loadingPromoCode = false;
      } else {
        let totalcartvalue = 0;
        if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
          encryptStorage
            .getItem("fasttrack_cart")
            .forEach(function (fasttrack_cartitem_arrayItem) {
              totalcartvalue =
                totalcartvalue +
                parseFloat(fasttrack_cartitem_arrayItem.fasttracksubtotal);
            });
        }
        if (typeof encryptStorage.getItem("cart") !== "undefined") {
          if (encryptStorage.getItem("cart").space_charges) {
            totalcartvalue =
              totalcartvalue +
              parseFloat(encryptStorage.getItem("cart").space_charges);
          } else {
            totalcartvalue =
              totalcartvalue + parseFloat(encryptStorage.getItem("cart").price);
          }
        }
        if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
          totalcartvalue =
            totalcartvalue +
            parseFloat(encryptStorage.getItem("lounge_cart").loungesubtotal);
        }
        totalcartvalue = (totalcartvalue.toFixed(2) * 100) / 100;
        let dsa_date_string = "";
        if (
          this.setbrandid == "RDU" ||
          this.setbrandid == "YHM" ||
          this.setbrandid == "OKC" ||
          this.setbrandid == "DEN" ||
          this.setbrandid == "SAT" ||
          this.setbrandid == "PV1" ||
          this.setbrandid == "RSW" ||
          this.setbrandid == "PIT" ||
          this.setbrandid == "SV1"
        ) {
          dsa_date_string = new Date().toLocaleString("en-GB", {
            timeZone: "US/Eastern",
          });
        }
        if (this.setbrandid == "HER") {
          dsa_date_string = new Date().toLocaleString("en-GB", {
            timeZone: "Asia/Nicosia",
          });
        }
        if (
          this.setbrandid == "NWI" ||
          this.setbrandid == "IP1" ||
          this.setbrandid == "INV" ||
          this.setbrandid == "NQY" ||
          this.setbrandid == "NOC" ||
          this.setbrandid == "JER" ||
          this.setbrandid == "LDY"
        ) {
          dsa_date_string = new Date().toLocaleString("en-GB", {
            timeZone: "Europe/London",
          });
        }

        dsa_date_string = dsa_date_string.split(",");
        let datepart_str = dsa_date_string[0].split("/");
        let datenow =
          datepart_str[2] +
          "-" +
          datepart_str[1] +
          "-" +
          datepart_str[0] +
          " " +
          dsa_date_string[1];
        let entry_date = this.item_start_date.split("/");
        entry_date =
          entry_date[2] +
          "-" +
          entry_date[1] +
          "-" +
          entry_date[0] +
          " " +
          this.item_start_time +
          ":00";

        entry_date = entry_date.replace("-", "/");
        entry_date = entry_date.replace("-", "/");
        let order_ref = "";
        let products = [];
        if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
          encryptStorage
            .getItem("fasttrack_cart")
            .forEach(function (fasttrack_cartitem_arrayItem) {
              console.log(
                "fasttrack_cartitem_arrayItem",
                fasttrack_cartitem_arrayItem
              );
              order_ref = encryptStorage.getItem("ftt_order_ref");
              let product_id_ftt = fasttrack_cartitem_arrayItem.product_id;

              let iftt = {
                product_id: "ftt",
                venue_id:fasttrack_cartitem_arrayItem.venue_id,
                entry_date_time:
                  fasttrack_cartitem_arrayItem.date_selected +
                  " " +
                  fasttrack_cartitem_arrayItem.time_selected +
                  ":00",
                total: fasttrack_cartitem_arrayItem.fasttracksubtotal,
                sub_product_id: product_id_ftt,
              };
              products.push(iftt);
            });
        }
        if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
          order_ref = encryptStorage.getItem("lounge_cart").lng_order_ref;
          let iftt = {
            product_id: "lng",
            venue_id:encryptStorage.getItem("lounge_cart").venue_id,
            entry_date_time:
              encryptStorage.getItem("lounge_cart").date_selected +
              " " +
              encryptStorage.getItem("lounge_cart").time_selected +
              ":00",
            total: encryptStorage.getItem("lounge_cart").loungesubtotal,
            sub_product_id: encryptStorage.getItem("lounge_cart").package_id,
          };
          products.push(iftt);
        }
        if (typeof encryptStorage.getItem("cart") !== "undefined") {
          order_ref = encryptStorage.getItem("order_ref");
          let icpk = {
            product_id: "cpk",
            venue_id:encryptStorage.getItem("cart").venue_id,
            entry_date_time: entry_date,
            total: encryptStorage.getItem("cart").space_charges
              ? encryptStorage.getItem("cart").space_charges
              : encryptStorage.getItem("cart").price,
            sub_product_id: encryptStorage.getItem("cart").product_id,
          };
          products.push(icpk);
        }
        let chdata =
          process.env.VUE_APP_DISCHL +
          this.setbrandid +
          order_ref +
          totalcartvalue;
        let hash = crypto.createHash("sha512");
        let hash_data = hash.update(chdata, "utf-8").digest("hex");
        let data = {
          brand_id: this.setbrandid,
          promo_code: discountcode_entered,
          gross_total: totalcartvalue,
          booking_ref_id: order_ref,
          transaction_date_time: datenow,
          hash_data: hash_data,
          products: products,
        };
        console.log("data", data);
        let secsaa = genkeys(
          process.env.VUE_APP_DIS_SECRETID,
          process.env.VUE_APP_DIS_SECRETKEY
        );
        let b1sa = secsaa.b1s;
        let v2sa = secsaa.v2s;
        let a1a = secsaa.a1;
        let v1a = secsaa.v1;
        let c2sa = secsaa.c2s;
        let uidgena = secsaa.uidgen;
        axios
          .post(process.env.VUE_APP_DIS_URL, data, {
            headers: {
              b1: b1sa,
              v2: v2sa,
              c2s: c2sa,
              a1: a1a,
              v1: v1a,
              c1: uidgena,
            },
          })
          .then(
            (response) => {
              let datasent = JSON.parse(response.request.response);
              if (datasent.user_exists == "Y") {
                if (datasent.data.discount_amount) {
                  let discount_amount = parseFloat(
                    datasent.data.discount_amount
                  );
                  let calculation = datasent.data.calculation;
					let dis_cpk = 0;
                  let dis_ftt = 0;
                  let disftt_arr = {};
                  let dis_lng = 0;
                  for (let n = 0; n < calculation.length; n++) {
                    let itemcart = calculation[n];
                    if (itemcart.product_id == "cpk") {
                      dis_cpk = itemcart.discount_amount;
                    }
                    if (itemcart.product_id == "ftt") {
                      disftt_arr["ftt"+itemcart.sub_product_id]={};   
                      let disftt = {};
                      disftt.discount_amount = itemcart.discount_amount;                  
                      disftt_arr["ftt"+itemcart.sub_product_id]=disftt;
                      dis_ftt = itemcart.discount_amount;
                    }
                    if (itemcart.product_id == "lng") {
                      dis_lng = itemcart.discount_amount;
                    }
                  }
                  //console.log("dis_cpk", dis_cpk);
                  console.log("dis_ftt", dis_ftt);
                  console.log("disftt_arr", disftt_arr);
                  
                  encryptStorage.setItem("dis_cpk", dis_cpk);
                  encryptStorage.setItem("dis_ftt", dis_ftt);
                  encryptStorage.setItem("disftt_arr", JSON.stringify(disftt_arr));
                  
                  encryptStorage.setItem("dis_lng", dis_lng);

                  if (typeof encryptStorage.getItem("cart") !== "undefined") {
                    let cartdata = encryptStorage.getItem("cart");
                    console.log("cartdata before", cartdata);
                    if (cartdata.fees_applicable) {
                      let fees_applicable = cartdata.fees_applicable;
                      if (fees_applicable == "Y") {
                        let fees = cartdata.fees;
                        let calculation_type = fees.calculation_type;
                        let calculation_value = fees.calculation_value;
                        console.log(
                          "cartdata.space_charges",
                          cartdata.space_charges
                        );
                        console.log("discount_amount", discount_amount);
                        let changed_space_charge =
                          parseFloat(cartdata.space_charges) -
                          parseFloat(discount_amount);
                        console.log(
                          "changed_space_charge",
                          changed_space_charge
                        );
                        cartdata.fees_amount_before_discount =
                          cartdata.fees_amount;
                        let fees_amount = 0;
                        if (calculation_type == "P") {
                          fees_amount =
                            (parseFloat(calculation_value) / 100) *
                            changed_space_charge;
                          fees_amount = parseFloat(fees_amount);
                          fees_amount = Math.ceil(fees_amount * 100) / 100;
                          cartdata.fees_amount = fees_amount;
                        } else {
                          fees_amount = parseFloat(calculation_value);
                          cartdata.fees_amount = fees_amount;
                        }
                        cartdata.pricefeesdisplay_before_discount =
                          cartdata.pricefeesdisplay;

                        let pricefeesdisplay =
                          "Parking Fees " +
                          this.currency +
                          changed_space_charge.toFixed(2);
                        let discounted_price = 0;
                        discounted_price =
                          discounted_price + parseFloat(changed_space_charge);
                        if (parseFloat(cartdata.booking_fee) > 0) {
                          pricefeesdisplay =
                            pricefeesdisplay +
                            " + " +
                            cartdata.booking_fee_name +
                            " " +
                            this.currency +
                            cartdata.booking_fee;
                          discounted_price =
                            discounted_price + parseFloat(cartdata.booking_fee);
                        }
                        if (parseFloat(cartdata.fees_amount) > 0) {
                          pricefeesdisplay =
                            pricefeesdisplay +
                            " + " +
                            cartdata.fees_policy_name +
                            " " +
                            this.currency +
                            cartdata.fees_amount;
                          discounted_price =
                            discounted_price + parseFloat(cartdata.fees_amount);
                        }
                        let price_discounted_without_discount =
                          parseFloat(discount_amount) + discounted_price;
                        cartdata.price_discounted_without_discount =
                          price_discounted_without_discount;
                        cartdata.discounted_price = discounted_price;
                        cartdata.pricefeesdisplay = pricefeesdisplay;
                        this.pricefeesdisplay = cartdata.pricefeesdisplay;
                        encryptStorage.setItem(
                          "cart",
                          JSON.stringify(cartdata)
                        );
                      }
                    }

                    console.log("cartdata after", cartdata);
                  }

                  encryptStorage.setItem("discount_amount", discount_amount);
                  encryptStorage.setItem(
                    "discount_coupon_applied",
                    discountcode_entered
                  );

                  this.discount_coupon_applied = discountcode_entered;
                  this.discount_amount_applied =
                    parseFloat(discount_amount).toFixed(2);
                  this.showtotalvaluecomputed();
                  this.totalcomputedvisible = true;
                  this.totalvisible = false;
                  //this.closeModal();
                  this.loadingPromoCode = false;
                }
              } else {
                //this.closeModal();
                let error_text = this.getrans(
                  "Promo code entered is not valid."
                );
                if (this.setbrandid === 'JER') {
                  error_text = this.getrans(
                    "Sorry, It looks like the promo code isn’t valid. Could you double-check and try again."
                  );
                }
                this.loadingPromoCode = false;
                Swal.fire({
                  title: "Alert",
                  icon: "error",
                  text: error_text,
                  confirmButtonText: "Close",
                  showCloseButton: true,
                });
              }
            },
            (error) => {
              //this.closeModal();
              console.log(error);
              //console.log(error.response);

              let error_text = this.getrans("Promo code entered is not valid.");
              if (error.response) {
                let error_data = error.response.data;
                if (error_data.data) {
                  error_text = this.getrans(error_data.data);
                }
              }

              this.loadingPromoCode = false;
              Swal.fire({
                title: "Alert",
                icon: "error",
                text: error_text,
                confirmButtonText: "Close",
                showCloseButton: true,
              });
            }
          );
      }
    },
    showtotalvaluecomputed() {
      let totalvalue = 0;
      console.log('fasttrack_cart',encryptStorage.getItem("fasttrack_cart"));
      if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
        let finaltotalvalue_ftt = [];
        let finaltotalvalue_ftt_obj = {};
        let disftt_arr = encryptStorage.getItem("disftt_arr");
       // console.log('disftt_arr.disftt_arr',encryptStorage.getItem("disftt_arr"));
        encryptStorage
          .getItem("fasttrack_cart")
          .forEach(function (fasttrack_cartitem_arrayItem) {
            let disamt = 0;
           console.log('this.fasttrack_cartitem_arrayItem',fasttrack_cartitem_arrayItem);
            
        if(disftt_arr["ftt"+fasttrack_cartitem_arrayItem.product_id])
        {
          disamt = disftt_arr["ftt"+fasttrack_cartitem_arrayItem.product_id].discount_amount;
          console.log('disamt',disamt);
        }            
        finaltotalvalue_ftt_obj["ftt"+fasttrack_cartitem_arrayItem.product_id]={};
        let ft_tot = {};
        ft_tot.finalamount = parseFloat(fasttrack_cartitem_arrayItem.fasttracksubtotal) -
        parseFloat(disamt);
        console.log('this.ft_tot',ft_tot);
        finaltotalvalue_ftt_obj["ftt"+fasttrack_cartitem_arrayItem.product_id]=ft_tot;
            totalvalue =
              totalvalue +
              parseFloat(fasttrack_cartitem_arrayItem.fasttracksubtotal);
            finaltotalvalue_ftt["ftt"+fasttrack_cartitem_arrayItem.product_id] =
              
              parseFloat(fasttrack_cartitem_arrayItem.fasttracksubtotal) -
              parseFloat(disamt);
          });
        this.finaltotalvalue_ftt = finaltotalvalue_ftt;
        console.log('this.finaltotalvalue_ftt',this.finaltotalvalue_ftt);
        console.log('this.finaltotalvalue_ftt_obj',finaltotalvalue_ftt_obj);
        encryptStorage.setItem("finaltotalvalue_ftt", JSON.stringify(finaltotalvalue_ftt_obj));
      }
      console.log("totalfeeaaa_ff", totalvalue);
      if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
        totalvalue =
          totalvalue +
          parseFloat(encryptStorage.getItem("lounge_cart").loungesubtotal);
        this.finaltotalvalue_lng =
          parseFloat(encryptStorage.getItem("lounge_cart").loungesubtotal) -
          parseFloat(encryptStorage.getItem("dis_lng"));
        encryptStorage.setItem("finaltotalvalue_lng", this.finaltotalvalue_lng);
      }

      if (typeof encryptStorage.getItem("coach_cart") !== "undefined") {
        totalvalue =
          totalvalue +
          parseFloat(encryptStorage.getItem("coach_cart").loungesubtotal);
        this.finaltotalvalue_coach =
          parseFloat(encryptStorage.getItem("coach_cart").coachsubtotal) -
          parseFloat(encryptStorage.getItem("dis_coach"));
      }

      if (typeof encryptStorage.getItem("atr_cart") !== "undefined") {
        totalvalue =
          totalvalue +
          parseFloat(encryptStorage.getItem("atr_cart").loungesubtotal);
        this.finaltotalvalue_atr =
          parseFloat(encryptStorage.getItem("atr_cart").atrsubtotal) -
          parseFloat(encryptStorage.getItem("dis_atr"));
      }

      if (typeof encryptStorage.getItem("cart") !== "undefined") {
        if (
          encryptStorage.getItem("cart").discounted_price &&
          encryptStorage.getItem("cart").price_discounted_without_discount
        ) {
          totalvalue =
            totalvalue +
            parseFloat(
              encryptStorage.getItem("cart").price_discounted_without_discount
            );
          this.finaltotalvalue_cpk =
            encryptStorage.getItem("cart").discounted_price;
        } else {
          totalvalue =
            totalvalue + parseFloat(encryptStorage.getItem("cart").price);
          this.finaltotalvalue_cpk =
            parseFloat(encryptStorage.getItem("cart").price) -
            parseFloat(encryptStorage.getItem("dis_cpk"));
        }
        console.log("finaltotalvalue_cpk", this.finaltotalvalue_cpk);
        encryptStorage.setItem("finaltotalvalue_cpk", this.finaltotalvalue_cpk);
      }
      console.log("totalfeeaaa", totalvalue);
      let discount_amount = encryptStorage.getItem("discount_amount");
      //let setcurrency = "1-GBP";
      if (discount_amount === null) {
        discount_amount = 0;
      } else {
        this.discount_amount_applied = parseFloat(discount_amount).toFixed(2);
        this.discount_coupon_applied = encryptStorage.getItem(
          "discount_coupon_applied"
        );
        this.totalcomputedvisible = true;
        this.totalvisible = false;
      }

      discount_amount = parseFloat(discount_amount);
      //let setcurrency_arr = setcurrency.split("-");
      // let currency = setcurrency_arr[1];
      // let currconv = setcurrency_arr[0];
      let totalfee = parseFloat(totalvalue);
      totalfee = totalfee - discount_amount;
      console.log("totalfee", totalfee);
      console.log("discount_amount", discount_amount);
      totalfee = totalfee.toFixed(2);

      this.finaltotalvalue = this.currency + " " + totalfee;

      this.$emit("finaltotalvalueChange", totalfee);
    },
    getrans(string) {
      var s = md5(string);
      var ss = this.setbrandid + "-" + this.lang.toUpperCase() + "-" + s;
      console.log(ss);
      return this.translations[ss] || string;
    },

    getLocalData(name) {
      return JSON.parse(sessionStorage.getItem(name));
    },
    setLocalData(name, value) {
      if (value != null) sessionStorage.setItem(name, value);
      else sessionStorage.removeItem(name);
    },
    formatDate_M_D_Y(date) {
      // when DD/MM/YY is passed returns MM/DD/YY for date picker widget
      date = date.split("/");
      date = date[1] + "/" + date[0] + "/" + date[2];
      return date;
    },
    goto_page(url) {
      window.location.href = url;
    },
    toggle_basket_model() {
      const new_event = new CustomEvent("basket_model_toggle", {
        detail: {
          data: "toggle",
        },
      });
      window.dispatchEvent(new_event);
    },
    encode_query_data(data) {
      const ret = [];
      for (let d in data)
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
      return ret.join("&");
    },
    goto_results() {
      encryptStorage.removeItem("discount_amount");
      encryptStorage.removeItem("discount_amount_applied");
      encryptStorage.removeItem("discount_coupon_applied");

      const data = {
        date1_date: this.formatDate_M_D_Y(this.item_start_date),
        date1_time: this.item_start_time,
        date2_date: this.formatDate_M_D_Y(this.item_end_date),
        date2_time: this.item_end_time,
        venue_select: this.getLocalData("venue_selected_cpk"),
        venue_select_name: this.getLocalData("venue_selected_name_cpk"),
      };
      const querystring = "?" + this.encode_query_data(data);
      let uri = "";
      uri = this.goto_page_url;
      this.goto_page(uri + querystring);
    },
    goto_results_ftt() {
      encryptStorage.removeItem("discount_amount");
      encryptStorage.removeItem("discount_amount_applied");
      encryptStorage.removeItem("discount_coupon_applied");

      //console.log('this.prop_fasttrack_cartitem',  this.prop_fasttrack_cartitem);

      const data = {
        date1_date: this.formatDate_M_D_Y(
          this.prop_fasttrack_cartitem[0].date_selected_display
        ),
        date1_time: this.prop_fasttrack_cartitem[0].time_selected,
        adults_select: this.prop_fasttrack_cartitem[0].ADL,
        children_select: this.prop_fasttrack_cartitem[0].CHD,
        infants_select: this.prop_fasttrack_cartitem[0].INF,
        venue_select: this.getLocalData("venue_selected_ftt"),
        venue_select_name: this.getLocalData("venue_selected_name_ftt"),
        lane_id: this.getLocalData("lane_id_ftt"),
      };
      const querystring = "?" + this.encode_query_data(data);
      let uri = "";
      uri = this.goto_page_url_ftt;
      this.goto_page(uri + querystring);
    },
    remove_cart() {
      if (encryptStorage.getItem("crosssell_shown") === "Yes") {
        let othp = 0;
        if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
          if (encryptStorage.getItem("fasttrack_cart").crossell) {
            othp = othp + 1;
          }
        }
        if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
          if (encryptStorage.getItem("lounge_cart").crossell) {
            othp = othp + 1;
          }
        }
        if (othp == 0) {
          encryptStorage.removeItem("cart");
          encryptStorage.removeItem("discount_amount");
          encryptStorage.removeItem("discount_amount_applied");
          encryptStorage.removeItem("discount_coupon_applied");
          encryptStorage.removeItem("dis_ftt");
          encryptStorage.removeItem("dis_cpk");
          encryptStorage.removeItem("dis_lng");
          encryptStorage.removeItem("finaltotalvalue_cpk");
          encryptStorage.removeItem("finaltotalvalue_lng");
          encryptStorage.removeItem("finaltotalvalue_ftt");
          this.update_cart_items();
          window.location.href = "/index.html";
        } else {
          this.deletecrossellcart();
        }
      } else {
        encryptStorage.removeItem("cart");
        encryptStorage.removeItem("discount_amount");
        encryptStorage.removeItem("discount_amount_applied");
        encryptStorage.removeItem("discount_coupon_applied");
        encryptStorage.removeItem("dis_ftt");
        encryptStorage.removeItem("dis_cpk");
        encryptStorage.removeItem("dis_lng");
        encryptStorage.removeItem("finaltotalvalue_cpk");
        encryptStorage.removeItem("finaltotalvalue_lng");
        encryptStorage.removeItem("finaltotalvalue_ftt");
        this.update_cart_items();
        this.checkcartempty();
      }
    },

    remove_promo() {
      console.log("remove promo");
      if (typeof encryptStorage.getItem("cart") !== "undefined") {
        let cartdata = encryptStorage.getItem("cart");
        if (cartdata.fees_applicable) {
          let fees_applicable = cartdata.fees_applicable;
          if (fees_applicable == "Y") {
            cartdata.fees_amount = cartdata.fees_amount_before_discount;
            cartdata.pricefeesdisplay =
              cartdata.pricefeesdisplay_before_discount;
            encryptStorage.setItem("cart", JSON.stringify(cartdata));
          }
        }

        if (cartdata.discounted_price) {
          delete cartdata.discounted_price;
        }
        if (cartdata.price_discounted_without_discount) {
          delete cartdata.price_discounted_without_discount;
        }
        encryptStorage.setItem("cart", JSON.stringify(cartdata));
      }
      encryptStorage.removeItem("discount_amount");
      encryptStorage.removeItem("discount_amount_applied");
      encryptStorage.removeItem("discount_coupon_applied");
      encryptStorage.removeItem("dis_ftt");
      encryptStorage.removeItem("dis_cpk");
      encryptStorage.removeItem("dis_lng");
      encryptStorage.removeItem("finaltotalvalue_cpk");
      encryptStorage.removeItem("finaltotalvalue_lng");
      encryptStorage.removeItem("finaltotalvalue_ftt");
      window.location.reload();
    },
    getbranded() {
      axios
        .post(
          process.env.VUE_APP_DOMAIN_DEVHUB +
            "hubapmydbauth/brandapi/getbrnslg_dev.php",
          "",
          {
            headers: {
              secret_id: process.env.VUE_APP_BRNDED_SECRETID,
              secret_key: process.env.VUE_APP_BRNDED_SECRETKEY,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data.slug);
          console.log(response.data.currency);
          if (response.data.code == "1") {
            this.setbrandid = response.data.slug;
            this.currency = response.data.currency;
            console.log("response.data.currency", response.data.currency);
            if (response.data.slug == "HER" || response.data.slug == "NOC") {
              this.currency = "€";
            }
            if (
              this.setbrandid == "RDU" ||
              this.setbrandid == "YHM" ||
              this.setbrandid == "OKC" ||
              this.setbrandid == "PV1" ||
              this.setbrandid == "SV1" ||
              this.setbrandid == "RSW" ||
              this.setbrandid == "PIT" 
            ) {
              var farray = this.item_start_date.split("/");
              console.log("item_start_date", this.item_start_date);
              this.item_start_date_m =
                farray[1] + "/" + farray[0] + "/" + farray[2];
              console.log("item_start_date_m", this.item_start_date_m);
              this.item_start_time_m = this.formatTime(this.item_start_time);
              var earray = this.item_end_date.split("/");
              this.item_end_date_m =
                earray[1] + "/" + earray[0] + "/" + earray[2];
              this.item_end_time_m = this.formatTime(this.item_end_time);
            } else {
              this.item_start_date_m = this.item_start_date;
              this.item_start_time_m = this.item_start_time;
              this.item_end_date_m = this.item_end_date;
              this.item_end_time_m = this.item_end_time;
            }
          }
        });
    },
    formatTime(timeString) {
      if (!timeString) return null;
      let [hourString, minute] = timeString.split(":");
      let hour = +hourString % 24;
      return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
    },
  },
  mounted() {
    console.log("item_price", this.item_price);
    console.log("item_title", this.item_title);

    this.getbranded();
    if (this.prop_parking_cartitem === undefined) {
      //do nothing
    }
    if (encryptStorage.getItem("discount_amount") === undefined) {
      this.totalvisible = true;
    } else {
      this.totalvisible = false;
      this.showtotalvaluecomputed();
    }
  },
};
</script>

<style lang="scss">
.basket-item + .basket-item {
  border-top: 1px solid #ddd;
  .basket-item__title {
    margin: 0.825rem 0 0rem;
    font-family: var(--details_subheadings_font_family, inherit);
    font-size: var(--details_subheadings_font_size, 1rem);
    font-weight: var(--details_subheadings_font_weight, 600);
    color: var(--details_subheadings_font_color, #1a2a40);
  }
}

.basket-item {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 1.125rem;
    font-family: var(--details_subheadings_font_family, inherit);
    font-weight: var(--details_subheadings_font_weight, bold);
    color: var(--details_subheadings_font_color, #1a2a40);
  }

  &__dates {
    display: flex;
    justify-content: flex-start;
    font-size: 0.8rem;
    color: #707070;
    font-weight: bold;
    margin: 0.5rem 0 0;
  }

  &__age-groups {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 0.925rem;
    color: #707070;
    font-weight: bold;
    margin: 0.5rem 0 0;
    gap: 1rem;
  }

  &__age-group {
  }

  &__price {
    font-size: 1.125rem;
    font-family: var(--basket_price_font_family, inherit);
    font-weight: var(--basket_price_font_weight, bold);
    color: var(--basket_price_font_color, #236ca3);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0 0.825rem;
  }

  &__promo-btn {
    a {
      display: block;
      width: 100%;
      text-align: left;
      font-weight: bold;
      color: #236ca3;
      text-decoration-line: underline;
    }
    padding-bottom: 1rem;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 160px;
    flex-wrap: wrap;
    span {
      display: inline-block;
    }
  }

  &__block--no-end-date {
    max-width: 100%;
    justify-content: flex-start;
    gap: 1rem;
  }

  &__start_to {
    padding: 0 1rem;
  }

  &__buttons {
    display: flex;
    gap: 0.25rem;
    > button {
      background: #e9e7e7;
      box-shadow: 0 2px 2px 0 #0000002e;
      border: 0px;
      font-size: 0.825rem;
      padding: 0.325rem 0.5rem;
      font-weight: bold;
      color: #236ca3;
    }
  }
}

.promo-dialog.p-dialog {
  width: 100% !important;
  max-width: 400px;
  margin: 1rem;
  .p-dialog-header {
    padding: 1rem;
    h5 {
      width: 100%;
      text-align: center;
      margin-left: 32px;
    }
  }

  .p-dialog-content {
    padding: 1rem;
    padding-bottom: 0;
    .promo-input {
      padding: 1rem;
      border: 1px solid #ddd;
      border-radius: 0.25rem;
      width: 100%;
      font-weight: bold;
      text-align: center;
    }
  }
  .p-dialog-footer {
    padding: 1rem;
    button.promo-update-button {
      margin-top: 0rem;
      width: 100%;
      padding: 1em;
      background: #034a94;
      color: #fff;
      border: 1px solid #034a94;
      border-radius: 0.25rem;
      font-weight: bold;
    }
  }
}

.basket-item-discount {
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.925rem;
  color: #707070;
  font-weight: bold;
}

.page-content__basket--box-style {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dfdfdf;
  padding: 1em 1.375rem;
  margin-bottom: 1rem;
}

.promo-box {
  span.basket-item__title {
    padding-bottom: 1rem;
    display: block;
  }

  .promo-input-btn-wrap {
    display: flex;
    width: 100%;
    padding-bottom: 1rem;
  }

  .promo-input {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    width: 70%;
    margin-right: 1rem;
    font-family: var(--basket_promo_input_font_family, inherit);
    font-size: var(--basket_promo_input_font_size, 1rem);
    font-weight: var(--basket_promo_input_font_weight, bold);
    color: var(--basket_promo_input_font_color, #0a3b74);
    text-transform: uppercase;
  }
  .promo-update-button {
    margin-top: 0rem;
    width: 30%;
    padding: 1em 1rem;
    text-align: center;
    background: var(--basket_promo_button_background, #034a94);
    color: var(--basket_promo_button_font_color, #fff);
    font-family: var(--basket_promo_button_font_family, inherit);
    font-size: var(--basket_promo_button_font_size, 1rem);
    font-weight: var(--basket_promo_button_font_weight, bold);
    border: var(--basket_promo_button_border, none);
    border-radius: var(--basket_promo_button_border_radius, 0.25rem);
  }

  .promo-wrap {
    display: flex;
    padding: 0.5rem;
    border: 1px solid #04a777;
    border-left: 7px solid #04a777;
    border-radius: 3px;
    background-color: #04a7770f;
    margin-bottom: 1rem;
    font-weight: bold;
    &--save {
      color: #707070;
      b {
        color: #1a2a40;
      }
    }
    &--applied {
      color: #236ca3;
    }
    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      i {
        color: #236ca3;
      }
    }
  }
}

.page-content__basket--box-style.is-model {
  background: #ffffff00;
  box-shadow: 0px 0px 0px #00000000;
}

.model-header {
  margin: 1rem 0rem 1rem 1rem;
  padding: 0rem 0rem 1rem;
  border-bottom: 1px solid #ddd;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  font-family: var(--basket_price_font_family, inherit);
  font-weight: var(--basket_price_font_weight, bold);
  color: var(--basket_price_font_color, #236ca3);
  &-close {
    padding: 0 1rem;
    cursor: pointer;
  }
}

.model-footer {
  margin: 0 1rem 1rem;
  &-subtotal {
    padding: 1rem 0rem;
    border-top: 1px solid #ddd;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    font-family: var(--basket_price_font_family, inherit);
    font-weight: var(--basket_price_font_weight, bold);
    color: var(--basket_price_font_color, #236ca3);
  }
  &-proceed {
    width: 100%;
    padding: 1rem 1rem;
    text-align: center;
    background: var(--basket_promo_button_background, #034a94);
    color: var(--basket_promo_button_font_color, #fff);
    font-family: var(--basket_promo_button_font_family, inherit);
    font-size: var(--basket_promo_button_font_size, 1rem);
    font-weight: var(--basket_promo_button_font_weight, bold);
    border: var(--basket_promo_button_border, none);
    border-radius: var(--basket_promo_button_border_radius, 0.25rem);
  }
}
</style>
